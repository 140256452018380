<template>
  <!-- <div class="index-content" :style="layoutPcMi ? 'width:70%;' : 'width:100%'"> -->
  <div
    class="index-content"
    :style="layoutPcMi ? 'width:1200px;' : 'width:100%;padding-top:0'"
  >
    <div v-if="layoutPcMi">
      <div class="carousel">
        <el-carousel autoplay :height="layoutPcMi ? '423px' : '200px'">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <h1 class="small">{{ item.img }}</h1> -->
            <img style="height: 100%; width: 100%" :src="item.img" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="carousel-nav-list">
          <div
            @mouseenter="navMouseente(index)"
            @mouseleave="navMouseleave(index)"
            @click="
              to(`/classificationGoods?id=${item.id}&classtitle=${item.name}`)
            "
            v-for="(item, index) in classifyList"
            class="carousel-nav-item"
          >
            <span>{{ item.name }}</span>
            <div v-if="item.checked" class="hover-content">
              <div class="hover-content2" v-for="(item2, index2) in item.data">
                <div
                  @click.stop="
                    to(
                      `/classificationGoods?id=${item2.id}&classtitle=${item2.name}`
                    )
                  "
                >
                  {{ item2.name }}
                </div>
                <!-- <div style="margin: 0 10px">></div> -->

                <!-- <div
                class="hover-content3"
                v-for="(item3, index3) in item2.content"
              >
                {{ item3 }}
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div @click="to('/classificationGoods')" class="list01">
        <img
          src="http://cdn.weilf.cn/Contents/site1/202208/e57c8a2c33d34a1e94a2d68317cda592.png"
          alt=""
        />
        <img
          src="http://cdn.weilf.cn/Contents/site1/202208/e6c9a0b25122432dac3def81dc6b3226.png"
          alt=""
        />
        <img
          src="http://cdn.weilf.cn/Contents/site1/202208/ed535082d50c4ae2ab5b4543141e4cf9.png"
          alt=""
        />
        <img
          src="http://cdn.weilf.cn/Contents/site1/202208/1f73b802eddb4b9cb12887fffcac73cf.png"
          alt=""
        />
      </div>
      <!-- <div class="brand">
      <div class="brand-title">
        <div>精选品牌</div>
        <div>查看全部 ></div>
      </div>
      <div class="brand-list">
        <img
          src="http://cdn.weilf.cn/Contents/site1/202207/2290e7538b1d43c096bb37aece58891f.jpg"
          alt=""
        />
      </div>
    </div> -->
      <div class="section">
        <div class="section-title">价格精选</div>
        <div class="section-list">
          <div
            class="selector-item"
            v-for="(item, index) in priceList"
            :key="index"
            @click="to('/classificationGoods?price=' + item)"
          >
            {{ item }}元{{ item == "2000" ? "以上" : "" }}
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in productList"
        :key="index"
        class="category-product"
      >
        <div class="product-title">
          <div>{{ item.name }}</div>
          <div
            @click="
              to(`/classificationGoods?id=${item.id}&classtitle=${item.name}`)
            "
          >
            查看全部 >
          </div>
        </div>
        <div class="product-list">
          <!-- <img
            @click="
              to(`/classificationGoods?id=${item.id}&classtitle=${item.name}`)
            "
            class="img1"
            :src="item.icon"
            alt=""
          /> -->
          <div
            @click="rouGoodDetail(item2.id)"
            v-for="(item2, index2) in item.list"
            class="product-item"
          >
            <img :src="item2.img" alt="" />
            <div>{{ item2.name }}</div>
            <div>
              ￥{{ item2.spec && item2.spec.price.toFixed(1) }}
              <span>￥{{ item2.spec && item2.spec.origin.toFixed(1) }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-divider v-if="false"><h2>最新商品</h2></el-divider>
      <div v-if="false" :class="layoutPcMi ? 'goods-flex' : 'goods-flex-ml'">
        <div
          v-for="(item, index) in produceList"
          :key="index"
          class="good-item"
          @mouseover="goodMouseOver('produceList', index)"
          @mouseleave="goodMouseLeave('produceList', index)"
          @click="rouGoodDetail(item.id)"
        >
          <div class="img">
            <div class="img-bg"></div>
            <img :src="item.img" alt="" />
            <div class="hover-btn" v-show="item.hover">
              <el-tooltip effect="dark" content="收藏" placement="top-start">
                <div class="good-collect" @click.stop="collect(item.id)">
                  <i class="el-icon-star-off"></i>
                </div>
              </el-tooltip>
              <el-tooltip effect="dark" content="对比" placement="top-start">
                <div @click.stop="toContrast(item)" class="good-collect">
                  <i class="el-icon-sort"></i>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="evaluation">
            <!-- <i class="el-icon-star-on"></i>
          <i class="el-icon-star-on"></i>
          <i class="el-icon-star-on"></i>
          <i class="el-icon-star-on"></i>
          <i class="el-icon-star-on"></i> -->
            <el-rate
              disabled-void-color="#d2d2d2"
              :value="item.star > 5 ? 5 : item.star"
              disabled
            ></el-rate>
          </div>
          <p>{{ item.name }}</p>
          <div class="add-cat">
            <el-button class="el-button-add-cart" v-show="item.hover"
              ><i class="el-icon-shopping-cart-full"></i>加入购物车</el-button
            >
          </div>
          <p class="price">￥{{ item.price }}</p>
        </div>
      </div>
      <!-- <el-image style="width: 100%; height: 500px" src="../../assets/logo.png"></el-image> -->
      <el-divider v-if="false"><h2>推荐商品</h2></el-divider>
      <div v-if="false" class="goods-flex">
        <div
          v-for="(item, index) in recommendList"
          :key="index"
          class="good-item"
          @mouseover="goodMouseOver('recommendList', index)"
          @mouseleave="goodMouseLeave('recommendList', index)"
          @click="rouGoodDetail(item.id)"
        >
          <div class="img">
            <div class="img-bg"></div>
            <img :src="item.img" alt="" />
            <div class="hover-btn" v-show="item.hover">
              <el-tooltip effect="dark" content="收藏" placement="top-start">
                <div class="good-collect" @click.stop="collect(item.id)">
                  <i class="el-icon-star-off"></i>
                </div>
              </el-tooltip>
              <el-tooltip effect="dark" content="对比" placement="top-start">
                <div @click.stop="toContrast(item)" class="good-collect">
                  <i class="el-icon-sort"></i>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="evaluation">
            <el-rate
              disabled-void-color="#d2d2d2"
              :value="item.star > 5 ? 5 : item.star"
              disabled
            ></el-rate>
          </div>
          <p>{{ item.name }}</p>
          <div class="add-cat">
            <el-button class="el-button-add-cart" v-show="item.hover"
              ><i class="el-icon-shopping-cart-full"></i>加入购物车</el-button
            >
          </div>
          <p class="price">￥{{ item.price }}</p>
        </div>
      </div>
      <el-divider v-if="false"><h2>特价商品</h2></el-divider>
      <div v-if="false" class="goods-flex">
        <div
          v-for="(item, index) in promotionList"
          :key="index"
          class="good-item"
          @mouseover="goodMouseOver('promotionList', index)"
          @mouseleave="goodMouseLeave('promotionList', index)"
          @click="rouGoodDetail(item.id)"
        >
          <div class="img">
            <div class="img-bg"></div>
            <img :src="item.img" alt="" />
            <div class="hover-btn" v-show="item.hover">
              <el-tooltip effect="dark" content="收藏" placement="top-start">
                <div class="good-collect" @click.stop="collect(item.id)">
                  <i class="el-icon-star-off"></i>
                </div>
              </el-tooltip>
              <el-tooltip effect="dark" content="对比" placement="top-start">
                <div @click.stop="toContrast(item)" class="good-collect">
                  <i class="el-icon-sort"></i>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="evaluation">
            <el-rate
              disabled-void-color="#d2d2d2"
              :value="item.star > 5 ? 5 : item.star"
              disabled
            ></el-rate>
          </div>
          <p>{{ item.name }}</p>
          <div class="add-cat">
            <el-button class="el-button-add-cart" v-show="item.hover"
              ><i class="el-icon-shopping-cart-full"></i>加入购物车</el-button
            >
          </div>
          <p class="price">￥{{ item.price }}</p>
        </div>
      </div>
      <el-backtop target=".index-content"></el-backtop>
    </div>

    <div v-else>
      <div class="h5-container">
        <!-- <div class="navbar-header">
          <div @click="popupShow = true">
            <van-icon name="search" color="#fff" />
          </div>
          <div @click="popupShow = true"><van-icon name="wap-nav" /></div>
        </div> -->
        <navbar-header></navbar-header>
        <div style="padding: 0 15px; margin-bottom: 20px">
          <van-swipe class="h5-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item>
              <img
                src="https://img1.baidu.com/it/u=2097594970,3387904523&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1677171600&t=4901753af7739f6a0a58c9f40ddbd736"
                alt=""
              />
            </van-swipe-item>
            <van-swipe-item>
              <img
                src="https://img2.baidu.com/it/u=2426126218,2026685529&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1677171600&t=48bcbfb0608a5c2de6cf03d15b014222"
                alt=""
              />
            </van-swipe-item>
          </van-swipe>
        </div>

        <div
          v-for="(item, index) in productList"
          :key="index"
          class="h5-category-product"
        >
          <div
            @click="
              to(`/classificationGoods?id=${item.id}&classtitle=${item.name}`)
            "
            class="widget-title"
          >
            <span>{{ item.name }}</span>
          </div>

          <div class="h5-product-list">
            <div
              v-for="(item2, index2) in item.list"
              :key="index2"
              class="h5-product-item"
              @click="rouGoodDetail(item2.id)"
            >
              <div class="h5-product-content">
                <img :src="item2.img" alt="" />
                <div class="h5-productname">
                  {{ item2.name }}
                </div>
                <!-- <div class="h5-origin"></div> -->
                <div class="h5-price">
                  ￥{{ item2.spec && item2.spec.price.toFixed(1) }}
                  <span
                    >￥{{ item2.spec && item2.spec.origin.toFixed(1) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin-bottom: 20px"
            v-if="item.list.length == 0"
          >
            暂无数据
          </div>
        </div>

        <van-popup
          class="popup-content"
          position="left"
          v-model="popupShow"
          style="height: 100%; width: 80%"
        >
          <van-search
            show-action
            v-model="searchVal"
            placeholder="请输入搜索关键词"
          >
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>

          <div class="popup-title">商品分类</div>

          <van-collapse v-model="activeClassify" accordion>
            <van-collapse-item
              v-for="(item, index) in classifyList"
              :key="index"
              :name="index + 1"
            >
              <template #title>
                <div
                  :style="{ color: index + 1 == activeClassify ? 'red' : '' }"
                >
                  {{ item.name }}
                </div>
              </template>
              <div class="collapse-content">
                <div v-for="(item2, index2) in item.child" :key="index2">
                  {{ item2.name }}
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../../request/http";
import navbarHeader from "../../components/navbarHeader.vue";
export default {
  name: "indexContent",
  components: {
    navbarHeader,
  },
  data() {
    return {
      searchVal: "",
      popupShow: false,
      hoverBtn: false,
      activeClassify: "",
      layoutPcMi: this.$store.state.layoutPcMi,
      bannerList: [
        {
          img: "https://img1.baidu.com/it/u=3023662727,1069485771&fm=253&fmt=auto&app=138&f=JPEG?w=1095&h=500",
        },
        {
          img: "https://img1.baidu.com/it/u=2097594970,3387904523&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1678208400&t=7b8f8fe4c5a747f308dba4f31fe0d08d",
        },
      ],
      produceList: [], //最新商品
      recommendList: [], // 推荐商品
      promotionList: [], //特价商品
      // page:1, //当前页
      // pageNum: 10 ,//每页几条数据
      priceList: [
        "1-20",
        "20-40",
        "40-60",
        "60-80",
        "80-100",
        "100-200",
        "200-400",
        "400-600",
        "600-800",
        "800-1000",
        "1000-2000",
        "2000",
      ], //价格
      productList: [], // 商品列表
      classifyIndex: 0,
      classifylength: 0,
      classifyList: [
        // {
        //   title: "食品礼盒",
        //   data: [{ name: "应季新品", content: ["南北干货", "米面粮油"] }],
        //   checked: false,
        // },
        // {
        //   title: "测试1",
        //   data: [{ name: "测试1", content: ["测试1", "测试1"] }],
        //   checked: false,
        // },
      ],
      classifyList2: [],
    };
  },
  mounted() {
    if (this.$route.query.appid) {
      localStorage.setItem("appid", this.$route.query.appid);
    }
    let time = setTimeout(() => {
      this.getBanner();
      // // 最新商品
      // this.getProductList("latest");
      // // 推荐商品
      // this.getProductList("recommend");
      // // 特价商品
      // this.getProductList("promotion");
      this.queryClassify();
      // this.queryTenant();
      clearTimeout(time);
    }, 100);
  },
  methods: {
    onSearch() {},
    navMouseente(index) {
      this.classifyList.forEach((item, i) => (item.checked = i == index));
    },
    navMouseleave(index) {
      this.classifyList[index].checked = false;
    },
    to(url) {
      this.$router.push(url);
    },
    collect(id) {
      http("column.php", {
        fun: "addColumnCollect",
        type: "c",
        module: "goods",
        bind_id: id,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "收藏成功",
            type: "success",
          });
          this.$emit("collectChange");
        }
      });
    },
    goodMouseOver(arr, index) {
      // this.hoverBtn = true
      this[arr][index].hover = true;
      console.log(arr);
    },
    goodMouseLeave(arr, index) {
      // this.hoverBtn = false
      this[arr][index].hover = false;
    },
    rouGoodDetail(id) {
      this.$router.push("/detail?id=" + id);
    },
    // 获取轮播图
    getBanner() {
      // http("column.php", {
      //   fun: "getColumnList",
      //   type: "activity",
      // }).then((res) => {
      //   if (res.code == 0) {
      //     let list = res.data;
      //     list.forEach((item) => {
      //       // if (item.id == "32e5QSTh7n4tkxBDJCS") {
      //       //   this.bannerList = item.slideshow;
      //       //   console.log(this.bannerList);
      //       // }
      //     });
      //   }
      // });
      // http("article.php", {
      //   fun: "getArticles",
      //   type: "3",
      // });
    },

    // 获取商品列表
    getProductList(goods_tag) {
      http("goods.php", {
        fun: "getGoodsEx",
        // is_baseinfo: '0',
        start_num: 0,
        get_num: 8,
        goods_tag,
      }).then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            this.$set(item, "hover", false);
          });

          if (goods_tag == "latest") {
            this.produceList = res.data;
          } else if (goods_tag == "recommend") {
            this.recommendList = res.data;
          } else if (goods_tag == "promotion") {
            this.promotionList = res.data;
          }
        }
      });
    },
    // 跳转对比页面
    toContrast(data) {
      console.log(data);
      let list = JSON.parse(localStorage.getItem("contrastList")) || [];
      let id = [];
      if (list.length > 0) {
        list.forEach((item) => {
          id.push(item.id);
        });
        id = id.join(",");
      }
      if (list.length == 4 && id.indexOf(data.id) == -1) {
        list[3] = data;
      } else if (list.length < 4 && id.indexOf(data.id) == -1) {
        list.push(data);
      }
      localStorage.setItem("contrastList", JSON.stringify(list));
      this.$router.push("/goodsContrast");
    },
    // 根据商品分类查询商品
    queryClassShop() {
      let that = this;
      let id = that.productList[that.classifyIndex].id;
      let index = that.productList[that.classifyIndex].index;
      http("goods.php", {
        fun: "getGoodsEx",
        goods_type_id: id,
        start_num: 0,
        get_num: 8,
      }).then((res) => {
        if (res.code == 0) {
          if (that.classifylength != that.classifyIndex + 1) {
            that.productList[index].list = res.data;
            that.classifyIndex++;
            that.queryClassShop();
          }
        }
      });
    },
    // 获取商品分类
    queryClassify() {
      let that = this;
      let productList = [];
      http("goods.php", {
        fun: "getGoodsType",
        // column_name: "首页",
      }).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          let endSlice = 5;
          list = list.slice(0, endSlice);
          that.classifyList = list;
          // console.log(res.data);
          that.classifyList2 = res.data;

          list.forEach((item, index) => {
            http("goods.php", {
              fun: "getGoodsEx",
              // goods_type: item.name,
              goods_type_id: item.id,
              start_num: 0,
              get_num: 10,
            }).then((res) => {
              if (res.code == 0) {
                // console.log(res.data);
                // that.productList[index] = res.data;
                productList.push({
                  name: item.name,
                  id: item.id,
                  list: res.data,
                  index,
                });
                console.log(productList.length);
                if (productList.length == endSlice) {
                  let compare = function (obj1, obj2) {
                    let val1 = obj1.index;
                    let val2 = obj2.index;
                    if (val1 < val2) {
                      return -1;
                    } else if (val1 > val2) {
                      return 1;
                    } else {
                      return 0;
                    }
                  };
                  // console.log(that.productList.sort(compare));
                  that.productList = productList.sort(compare);
                }
              }
            });
          });
          // setTimeout(() => {
          //   var compare = function (obj1, obj2) {
          //     var val1 = obj1.index;
          //     var val2 = obj2.index;
          //     if (val1 < val2) {
          //       return -1;
          //     } else if (val1 > val2) {
          //       return 1;
          //     } else {
          //       return 0;
          //     }
          //   };
          //   // console.log(that.productList.sort(compare));
          //   that.productList = productList.sort(compare);
          // }, 500);

          return;
          list.forEach((item, index) => {
            that.classifyList.push({
              title: item.name,
              data: item.child,
              checked: false,
              id: item.id,
            });
          });

          that.classifylength = list.length;
          list.forEach((item, index) => {
            that.productList.push({
              icon: item.img,
              name: item.name,
              id: item.id,
              index: index,
              list: [],
            });
          });

          ///////////////////
          list.forEach((item, index) => {
            http("goods.php", {
              fun: "getGoodsEx",
              goods_type_id: item.id,
              start_num: 0,
              get_num: 8,
            }).then((res) => {
              if (res.code == 0) {
                that.productList[index].list = res.data;
              }
            });
          });
          ///////////////////////////
          return;
          that.queryClassShop();
        }
      });
    },
    queryTenant() {
      http("tenant.php", {
        fun: "getTenantInfo",
        host: window.location.host,
        // host: "shop.shangjiu-gift.com.cn",
      }).then((res) => {
        if (res.code == 0) {
          document.title = res.data[0].title;
          localStorage.setItem("lesseeMsg", JSON.stringify(res.data[0]));
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/index.scss";
.index-content {
  //@include body;
  padding-top: 20px;
  .carousel {
    position: relative;
    .carousel-nav-list {
      position: absolute;
      top: 0;
      z-index: 999;
      background: rgba(105, 101, 101, 0.6);
      width: 200px;
      .carousel-nav-item {
        text-align: center;
        color: #fff;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        font-size: 14px;
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
        span {
          &:hover {
            color: #000;
          }
        }
        .hover-content {
          position: absolute;
          top: 0;
          left: 200px;
          height: 423px;
          background: #fff;
          width: 700px;
          z-index: 999;
          color: #000;
          text-align: left;

          &:hover {
            cursor: auto;
          }
          .hover-content2 {
            display: flex;
            padding: 0 20px;
            cursor: pointer;
            font-size: 12px;
            &:hover {
              color: #c51323;
            }
            .hover-content3 {
              color: #808080;
              font-size: 12px;
              margin-right: 10px;
              cursor: pointer;
              &:hover {
                color: #c51323;
              }
            }
          }
        }
      }
    }
    // .hover-content {
    //   position: absolute;
    //   top: 0;
    //   z-index: 999;
    //   left: 200px;
    //   bottom: 0;
    //   background: #fff;
    //   width: 700px;
    // }
  }

  .list01 {
    background-color: white;
    cursor: pointer;
  }
  .brand {
    .brand-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      div {
        &:first-child {
          font-size: 24px;
        }
        &:last-child {
          cursor: pointer;
        }
      }
    }
    .brand-list {
    }
  }
  .section {
    margin-top: 10px;
    margin-bottom: 20px;
    .section-title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .section-list {
      display: flex;
      flex-wrap: wrap;
      .selector-item {
        width: 175px;
        height: 60px;
        background: #fff;
        padding: 9px;
        margin: 8px 0 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 18px;
        border-radius: 5px;
        &:nth-child(1) {
          margin-left: 0;
        }
        &:nth-child(7) {
          margin-left: 0;
        }
      }
    }
  }
  .category-product {
    margin-bottom: 30px;
    .product-title {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        &:first-child {
          font-size: 24px;
        }
        &:last-child {
          cursor: pointer;
        }
      }
    }
    .product-list {
      display: flex;
      flex-wrap: wrap;
      .img1 {
        width: 474px;
        height: 300px;
        margin: 0 6px 10px 0;
        cursor: pointer;
        &:hover {
          transform: scale(1.1); //原本的图片的大小，图片原来的大小不变
          transition: all 0.7s;
        }
      }
      .product-item {
        // padding: 0 10px;
        background: #fff;
        width: 234px;
        margin: 0 6px 10px 0;
        height: 312px;
        text-align: center;
        cursor: pointer;
        div {
          &:nth-child(2) {
            font-size: 14px;
            margin-top: 20px;
            margin-bottom: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }
          // &:nth-child(3) {
          //   font-size: 16px;
          //   color: #999;
          //   margin-bottom: 5px;
          // }
          &:nth-child(3) {
            font-size: 16px;
            color: #ff3333;

            span {
              text-decoration: line-through;
              color: #999;
            }
          }
        }
        img {
          width: 214px;
          height: 214px;
          &:hover {
            transform: scale(1.1); //原本的图片的大小，图片原来的大小不变
            transition: all 0.7s;
          }
        }
      }
    }
  }
}
.el-divider {
  margin: 30px 0;
}
.el-carousel__item {
  line-height: 500px;
  text-align: center;
  background-color: #99a9bf;
}

.goods-flex {
  display: flex;
  flex-wrap: wrap;
  .good-item {
    width: 25%;
    padding: 0 12px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    .el-button-add-cart {
      position: absolute;
      left: calc(50% - 60px);
    }
    .img {
      position: relative;
      img {
        width: 100%;
        height: 260px;
      }
      .hover-btn {
        position: absolute;
        top: 50%;
        left: calc(50% - 60px);
        display: flex;
        div {
          @include circular($border: none);
          background-color: white;
          @include flexCenter;
        }
        div:first-child {
          margin-right: 20px;
        }
      }
      .good-collect {
        &:hover {
          background: rgb(255, 218, 0);
          color: #000;
        }
        .el-icon-sort {
          transform: rotateZ(90deg);
        }
      }
    }
    &:hover {
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
      .img-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    .price {
      color: #f30240;
      font-size: 18px;
      padding-bottom: 20px;
      font-weight: bold;
    }
    .evaluation {
      margin-top: 10px;
    }
    .evaluation i {
      color: #d2d2d2;
    }
    .add-cat {
      :deep(.el-button) {
        background-color: rgb(255, 218, 0);
        color: #fff;
        border-color: rgb(255, 218, 0);
      }
    }
  }
}
// .goods-flex {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, 18%);
//   grid-gap: 40px;
//   // display: flex;
//   // flex-wrap: wrap;
//   .good-item {
//     // width: 25%;
//     // padding: 0 20px;
//     text-align: center;
//     margin-bottom: 20px;
//     cursor: pointer;
//     box-sizing: border-box;
//     position: relative;
//     .el-button-add-cart {
//       position: absolute;
//       left: calc(50% - 60px);
//     }
//     .img {
//       position: relative;
//       img {
//         width: 100%;
//       }
//       .hover-btn {
//         position: absolute;
//         top: 50%;
//         left: calc(50% - 60px);
//         display: flex;
//         div {
//           @include circular($border: none);
//           background-color: white;
//           @include flexCenter;
//         }
//         div:first-child {
//           margin-right: 20px;
//         }
//       }
//     }
//     &:hover {
//       box-shadow: 0 0 10px 10px rgba(0, 0, 0, .1);
//       .img-bg {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         background-color: rgba(255, 255, 255, .2);
//       }
//     }
//     .price {
//       color: #F30240;
//       font-size: 18px;
//       padding-bottom: 20px;
//       font-weight: bold;
//     }
//     .evaluation i {
//       color: #D2D2D2;
//     }
//   }
// }

.h5-container {
  background: #fff;
  .navbar-header {
    background: #c51323;
    display: flex;
    justify-content: space-between;
    padding: 1px;
    margin-bottom: 20px;
    div {
      padding: 10px;
      border-radius: 5px;
      &:first-child {
        background: #000;
      }
      &:last-child {
        background: #efefef;
      }
    }
  }
  .h5-swipe {
    img {
      height: 151px;
      width: 100%;
    }
  }
  .h5-category-product {
    .widget-title {
      padding: 0;
      margin: 0 0 30px;
      text-align: center;
      border-bottom: none;
      height: 36px;
      line-height: 36px;
      clear: both;
      color: #000;
      position: relative;
      span {
        display: inline-block;
        position: relative;
        padding: 0 24px;
        font-size: 28px;
        background-color: #fff;
      }
      &::before {
        content: "";
        position: absolute;
        top: 56%;
        left: 0;
        height: 1px;
        width: 100%;
        background: #e5e5e5 none repeat scroll 0 0;
      }
    }
    .h5-product-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0 5px;
      .h5-product-item {
        width: 50%;
        padding: 0 5px;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 20px;
        .h5-product-content {
          border: 1px solid #eee;
          padding-bottom: 10px;

          .h5-productname {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #444;
            font-size: 15px;
            margin-top: 10px;
            padding: 0 2px;
            margin-bottom: 5px;
          }
          .h5-origin {
            color: #999;
          }
          .h5-price {
            color: red;
            font-size: 14px;

            span {
              color: #999;
              text-decoration: line-through;
            }
          }
          img {
            width: 100%;
            height: 163px;
          }
        }
      }
    }
  }

  .popup-content {
    .popup-title {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .collapse-content {
      margin-left: 20px;
      div {
        border-bottom: 1px dashed #e8e8e8;
        padding-bottom: 5px;
        margin-bottom: 5px;
        &:last-child {
          border: 0;
        }
      }
    }
  }
}

.goods-flex-ml {
  @extend .goods-flex;
  grid-template-columns: repeat(auto-fit, 40%) !important;
}
</style>
