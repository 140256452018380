<template>
  <div>
    <div class="navbar-header">
      <div @click="popupShow = true">
        <van-icon name="search" color="#fff" />
      </div>
      <div @click="popupShow = true"><van-icon name="wap-nav" /></div>
    </div>

    <van-popup
      class="popup-content"
      position="left"
      v-model="popupShow"
      style="height: 100%; width: 80%"
    >
      <van-search
        show-action
        v-model="searchVal"
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>

      <div class="popup-title">商品分类</div>
      <van-pull-refresh v-if="false" v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <!-- <van-cell  v-for="item in list" :key="item" :title="item" /> -->
          <van-collapse v-model="activeClassify" accordion>
            <van-collapse-item
              v-for="(item, index) in classifyList"
              :key="index"
              :name="index + 1"
            >
              <template #title>
                <div
                  :style="{ color: index + 1 == activeClassify ? 'red' : '' }"
                >
                  {{ item.name }}
                </div>
              </template>
              <div class="collapse-content">
                <div v-for="(item2, index2) in item.child" :key="index2">
                  {{ item2.name }}
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-list>
      </van-pull-refresh>

      <van-collapse v-model="activeClassify" accordion>
        <div @click="toAllProduct" class="all-product">全部商品</div>
        <van-collapse-item
          v-for="(item, index) in classifyList"
          :key="index"
          :name="index + 1"
        >
          <template #title>
            <div :style="{ color: index + 1 == activeClassify ? 'red' : '' }">
              {{ item.name }}
            </div>
          </template>
          <div class="collapse-content">
            <div
              @click="classifySearch(item2)"
              v-for="(item2, index2) in item.child"
              :key="index2"
            >
              {{ item2.name }}
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-popup>
  </div>
</template>

<script>
import { http } from "../../request/http";
export default {
  data() {
    return {
      popupShow: false,
      searchVal: "",
      activeClassify: "",
      classifyList: [],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      sliceEnd: 20,
      oldclassifyList: [],
    };
  },
  mounted() {
    this.queryClassify();
  },
  methods: {
    onSearch() {
      this.popupShow = false;
      let route = this.$route.query;
      if (this.$route.query.search == this.searchVal) {
        this.$router.replace(
          `/classificationGoods?search=${this.searchVal}&repetition=${
            route.repetition == "" ? 1 : route.repetition == 2 ? 1 : 2
          }`
        );
        return;
      }
      this.$router.replace("/classificationGoods?search=" + this.searchVal);
      // this.$router.push("/classificationGoods?search=" + this.searchVal);
    },
    // 获取商品分类
    queryClassify() {
      let that = this;
      http("goods.php", {
        fun: "getGoodsType",
        // column_name: "首页",
      }).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list = list.slice(0, this.sliceEnd);
          that.oldclassifyList = res.data;
          that.classifyList = list;
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      console.log("sss");
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }
        this.sliceEnd += 20;
        this.classifyList = this.oldclassifyList.slice(0, this.sliceEnd);

        // for (let i = 0; i < 10; i++) {
        //   this.list.push(this.list.length + 1);
        // }
        this.loading = false;

        // if (this.list.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);
    },
    classifySearch(data) {
      console.log(data);
      this.popupShow = false;

      let route = this.$route.query;
      if (this.$route.query.id == data.id) {
        this.$router.replace(
          `/classificationGoods?id=${data.id}&classtitle=${
            data.name
          }&repetition=${
            route.repetition == "" ? 1 : route.repetition == 2 ? 1 : 2
          }`
        );
        // this.$emit("classifyChange"); //触发transfer方法，this.user 为向父组件传递的数据
        return;
      }
      this.$router.replace(
        `/classificationGoods?id=${data.id}&classtitle=${data.name}`
      );
      // this.$emit("classifyChange"); //触发transfer方法，this.user 为向父组件传递的数据
    },
    toAllProduct() {
      let route = this.$route.query;
      this.popupShow = false;
      this.$router.replace(
        `/classificationGoods?allshop=true&repetition=${
          route.repetition == "" ? 1 : route.repetition == 2 ? 1 : 2
        }`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-header {
  background: #c51323;
  display: flex;
  justify-content: space-between;
  padding: 1px;
  margin-bottom: 20px;
  div {
    padding: 10px;
    border-radius: 5px;
    &:first-child {
      background: #000;
    }
    &:last-child {
      background: #efefef;
    }
  }
}

.popup-content {
  .popup-title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .all-product {
    padding: 10px 0;
    color: #323233;
    font-size: 14px;
    border-bottom: 0.5px solid #ebedf0;
    margin: 0 16px;
  }
  .collapse-content {
    margin-left: 20px;
    div {
      border-bottom: 1px dashed #e8e8e8;
      padding-bottom: 5px;
      margin-bottom: 5px;
      &:last-child {
        border: 0;
      }
    }
  }
}
</style>